import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Button, Modal, Table, message } from 'antd';
import { useCart } from '../CartContext';
import './EventsSection.css';
import { FaMapMarkerAlt } from 'react-icons/fa';

function EventsSection() {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [addedToCart, setAddedToCart] = useState(null); // Track which ticket was added
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'events'));
        const eventsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEvents(eventsData);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  const showModal = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    setAddedToCart(null); // Reset button state
  };

  const handleAddToCart = (ticket) => {
    addToCart({
      event: selectedEvent.name,
      ticketType: ticket.type,
      price: ticket.price,
    });
    setAddedToCart(ticket.type); // Mark ticket as added
    message.success('Added to cart successfully!'); // Show success message
    setTimeout(() => setAddedToCart(null), 2000); // Reset button state after 2 seconds
  };

  const columns = [
    {
      title: 'Ticket Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${parseFloat(price || 0).toFixed(2)}`, // Format price for display
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type={addedToCart === record.type ? 'default' : 'primary'}
          onClick={() => handleAddToCart(record)}
          disabled={addedToCart === record.type}
        >
          {addedToCart === record.type ? '✔ Added' : 'Add to Cart'}
        </Button>
      ),
    },
  ];
  

  return (
    <div className="events-section">
      <h2>Upcoming Events</h2>
      <div className="events-grid">
      {events.map(event => (
  <div key={event.id} className="event-card">
    <img src={event.imageURL} alt={event.name} className="event-image" />
    <h3>{event.name}</h3>

    {/* ✅ Event Location */}
    {event.location && (
      <div className="event-location" style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
        <FaMapMarkerAlt style={{ marginRight: '6px', color: 'red' }} />
        <span>{event.location}</span>
      </div>
    )}

    {/* ✅ Datetime with Padding */}
    {event.datetime && (
      <div
        className="event-datetime"
        style={{ marginTop: '8px', color: '#555', fontSize: '14px', paddingBottom: '8px' }}
      >
        📅 {event.datetime}
      </div>
    )}

    {/* ✅ Buy Tickets Button with Padding */}
    <Button
      type="primary"
      style={{ marginTop: '10px', padding: '10px 20px' }} // Added spacing and button padding
      onClick={() => showModal(event)}
    >
      Buy Tickets
    </Button>
  </div>
))}



      </div>

      {selectedEvent && (
  <Modal
    title={selectedEvent.name}
    open={isModalOpen}
    onCancel={handleCancel}
    footer={null}
  >
    <img src={selectedEvent.imageURL} alt={selectedEvent.name} className="event-modal-image" />

    {/* ✅ Centered Location Section */}
    {selectedEvent.location && (
      <div
        className="event-location"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // ✅ Center the location
          marginBottom: '10px'
        }}
      >
        <FaMapMarkerAlt style={{ marginRight: '8px', color: 'red' }} />
        <span>{selectedEvent.location}</span>
      </div>
    )}

    {/* ✅ Centered Date & Time */}
    {selectedEvent.datetime && (
      <div
        className="event-datetime"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // ✅ Center the date & time
          marginTop: '8px',
          marginBottom: '10px',
          color: '#555',
          fontSize: '14px'
        }}
      >
        📅 {selectedEvent.datetime}
      </div>
    )}

    <p>{selectedEvent.description}</p>

    <Table
      dataSource={selectedEvent.tickets}
      columns={columns}
      pagination={false}
      rowKey="type"
    />
  </Modal>
)}



    </div>
  );
}

export default EventsSection;
