import React, { useState } from 'react';
import './Header.css'; // Custom styles for the header
import logo from '../assets/logo.png'; // Import the logo image
import { Button, InputNumber, Modal, Table } from 'antd'; // Import Ant Design components
import {
  UserOutlined,
  ShoppingCartOutlined,
  DeleteOutlined,
} from '@ant-design/icons'; // Icons
import { useCart } from '../CartContext'; // Import Cart Context
import { useNavigate } from 'react-router-dom';

function Header() {
  const { cart, updateQuantity, clearCart } = useCart(); // Use Cart Context
  const [isCartModalOpen, setIsCartModalOpen] = useState(false); // State for detailed cart dialog
  const navigate = useNavigate();

  // Calculate the total price of items in the cart
  const calculateTotal = () =>
    cart.reduce((total, item) => {
      const itemPrice = item.price || 0; // Ensure price is numeric
      const itemQuantity = item.quantity || 0; // Ensure quantity is valid
      return total + itemPrice * itemQuantity; // Multiply price and quantity
    }, 0);

  // Open and close the detailed cart dialog
  const openCartModal = () => setIsCartModalOpen(true);
  const closeCartModal = () => setIsCartModalOpen(false);

  const handlePayNow = () => {
    closeCartModal(); // Close the modal
    navigate('/payment'); // Navigate to the payment page
  };

  // Table columns for the detailed cart dialog
  const columns = [
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: 'Ticket Type',
      dataIndex: 'ticketType',
      key: 'ticketType',
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (_, record) => (
        <InputNumber
          min={1}
          value={record.quantity}
          onChange={(value) => updateQuantity(record, value)}
        />
      ),
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, record) => `$${(record.quantity * record.price).toFixed(2)}`,
    },
  ];

  return (
    <div className="header">
      {/* Logo and Title */}
      <div
        className="logo-container"
        onClick={() => navigate('/')}
        style={{ cursor: 'pointer' }}
      >
        <img src={logo} alt="Black Canyon Tickets" className="logo" />
        <span className="logo-text">Black Canyon Tickets</span>
      </div>

      {/* Navigation Menu */}
      <div className="nav-menu">
        <a href="#about" className="nav-link">
          About Us
        </a>
        <a href="#contact" className="nav-link">
          Contact
        </a>
        <a href="/solutions/index.html" target="_blank" className="nav-link">
          Solutions
        </a>
        <div className="nav-dropdown">
          <span className="nav-link">Coming Events</span>
          <div className="dropdown-content">
            <a href="#event1">Event 1</a>
            <a href="#event2">Event 2</a>
          </div>
        </div>
        <a href="/privacy-policy" className="nav-link">
          Privacy Policy
        </a>
        <a href="/terms-and-conditions" className="nav-link">
          Terms & Conditions
        </a>
      </div>

      {/* Profile and Cart Buttons */}
      <div className="header-buttons">
        <Button shape="circle" icon={<UserOutlined />} />
        <div className="cart-container">
          <Button
            type="primary"
            icon={<ShoppingCartOutlined />}
            className="cart-button"
          >
            Cart/${calculateTotal().toFixed(2)}
          </Button>
          <div className="cart-hover">
            <h3>Cart Summary</h3>
            {cart.length === 0 ? (
              <p>Your cart is empty.</p>
            ) : (
              <ul>
                {cart.map((item, index) => (
                  <li key={index} className="cart-item">
                    <span>
                      {item.event} ({item.ticketType})
                    </span>
                    <div className="cart-controls">
                      <InputNumber
                        min={1}
                        value={item.quantity}
                        onChange={(value) => updateQuantity(item, value)}
                      />
                      <span className="cart-item-price">
                        ${(item.quantity * item.price).toFixed(2)}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {cart.length > 0 && (
              <>
                <div className="cart-footer">
                  <Button
                    type="link"
                    className="view-cart-button"
                    onClick={openCartModal}
                  >
                    View Cart
                  </Button>
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    className="clear-cart-icon"
                    onClick={clearCart}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Detailed Cart Modal */}
      <Modal
        title="Your Cart"
        open={isCartModalOpen}
        onCancel={closeCartModal}
        footer={[
          <Button key="close" onClick={closeCartModal}>
            Close
          </Button>,
          <Button key="pay" type="primary" onClick={handlePayNow}>
            Pay Now
          </Button>,
        ]}
      >
        <Table
          dataSource={cart}
          columns={columns}
          pagination={false}
          rowKey={(record) => `${record.event}-${record.ticketType}`}
        />
        <div className="cart-total">
          <h3>Total: ${calculateTotal().toFixed(2)}</h3>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
